<template>
	<div
		:class="stackedLayout ? 'mx-3' : ''"
		class="row no-gutters mb-2"
		:style="['xs', 'sm', 'md', 'lg'].includes(windowWidth) ? 'height: 5em;' : 'height: 2.5em;'">
		<div class="col">
			<div class="d-flex p-0 align-items-center">
				<div class="h-100 mr-3">
					<img
						:src="thumbnail"
						style="height: 2.5em;"
						class="float-right">
				</div>
				<div
					class="text-truncate"
					:class="stackedLayout ? 'mb-2' : ''">
					<span
						:class="stackedLayout ? 'text-medium' : ''">
						{{ translate(configuration) }}
						<template v-if="backorderInfo && backorderInfo.will_backorder">
							<i
								v-b-tooltip
								:title="translate('will_be_backordered')"
								class="fas fa-exclamation-triangle text-warning text-small" />
						</template>
					</span>
					<br>
					<span>
						<b>{{ price }}</b>
						<template v-if="exchange">
							&nbsp;
							<span class="text-small exchange-text">{{ exchangePrice }}</span>
						</template>
						<template v-if="bvs > 0">
							&nbsp;-&nbsp;
							<span class="text-medium"> {{ bvs }} BVs</span>
						</template>
					</span>
				</div>
			</div>
		</div>
		<div class="col-12 col-xl-auto">
			<div class="justify-content-end d-flex w-100 align-items-center">
				<quantity-select
					:init-qty="initQty"
					:select-limit="10"
					:max="99"
					include-zero
					:container-class="`${['xs', 'sm', 'md'].includes(windowWidth) ? 'text-center' : 'text-right'} w-100 ${stackedLayout ? '' : 'pr-3'}`"
					sub-container-class="justify-content-end w-100"
					@change="$emit('change', $event)" />
			</div>
		</div>
	</div>
</template>
<script>
import QuantitySelect from '@/components/QuantitySelect';
import { Products, Purchase } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'ProductConfiguration',
	messages: [Products, Purchase],
	components: { QuantitySelect },
	mixins: [WindowSizes],
	props: {
		configuration: {
			type: String,
			required: true,
		},
		thumbnail: {
			type: String,
			required: true,
		},
		initQty: {
			type: Number,
			required: true,
		},
		stackedLayout: {
			type: Boolean,
			default: false,
		},
		backorderInfo: {
			type: Object,
			default: null,
		},
		price: {
			type: String,
			required: true,
		},
		exchange: {
			type: Boolean,
			required: true,
		},
		exchangePrice: {
			type: String,
			required: true,
		},
		bvs: {
			type: Number,
			default: 0,
		},
	},
};
</script>
